import { BehaviorSubject } from 'rxjs';

const currentUserSubject = new BehaviorSubject(sessionStorage.getItem('currentUser'));
const refreshToken = new BehaviorSubject(localStorage.getItem('refreshToken'));

var Constants = require('../constants');

export const authenticationService = {
    login,
    logout,
    register,
    authHeader,
    currentUser: currentUserSubject.asObservable(),
    refreshToken: refreshToken.asObservable(),
    refresh,
    get refreshTokenValue () { return refreshToken.value },
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${Constants.api_url}/token/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            sessionStorage.setItem('currentUser', user.access);
            localStorage.setItem('refreshToken', user.refresh);
            currentUserSubject.next(user.access);
            refreshToken.next(user.refresh);

            return user.access;
        });
}

function register(values) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };

    return fetch(`${Constants.api_url}/user/register`, requestOptions)
        .then(handleResponse);
}

function logout() {
    sessionStorage.removeItem('currentUser');
    localStorage.removeItem('refreshToken');
    currentUserSubject.next(null);
    refreshToken.next(null);
}

function refresh() {
    const currentToken = authenticationService.refreshTokenValue;
    console.info('try refresh with ' + currentToken);
    if (currentToken) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refresh: currentToken })
        };

        return fetch(`${Constants.api_url}/token/refresh/`, requestOptions)
            .then(handleResponse)
            .then(user => {
                localStorage.setItem('accessToken', user.access);
                currentUserSubject.next(user.access);
                return user;
            }).catch(() => {});
    }
}

export function authHeader() {
    let currentUser;

    if (authenticationService.currentUserValue == null || authenticationService.currentUserValue === undefined) {
        currentUser = refresh();
    }
    else {
        currentUser = authenticationService.currentUserValue;
    }

    if (currentUser) {
        return { Authorization: `Bearer ${currentUser}` };
    } else {
        return {};
    }
}

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                authenticationService.logout();
                window.history.push('/login');
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
