import React, {Fragment} from 'react';
import * as Constants from "../../internals/constants";
import {authHeader, handleResponse} from "../../internals/authservice/authentication.service";


export class Accesos extends React.Component {
    constructor(props) {
        super(props);
        let requestOptions = { method: 'GET', headers: authHeader() };

        this.state = {}
    }

    componentDidMount() {
        let requestOptions = { method: 'GET', headers: authHeader() };
        fetch(`${Constants.api_url}/user/history`, requestOptions).then(handleResponse).then(data =>  this.setState({...data}));
    }

    render() {
        return (
            <Fragment>
                <div className="container mx-auto my-5 p-5 flex flex-row">
                    {this.state.hasOwnProperty('accesos') &&
                           <div className="container mx-auto px-4 sm:px-8 max-w-md rounded-lg shadow-md">
                                    <div className="pt-2">
                                        <div className="-mx-4 sm:-mx-8 py-4 overflow-x-auto">
                                            <div className="inline-block min-w-full overflow-hidden  rounded-lg">
                                                <h5 className="ml-4 text-xl font-semibold mb-2">Últimos accesos registrados</h5>
                                                <table className="min-w-full leading-normal m-4">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col"
                                                            className="px-2 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm font-normal">
                                                            Entrada
                                                        </th>
                                                        <th scope="col"
                                                            className="px-2 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm  font-normal">
                                                            Salida
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.accesos.map((acceso, i) => {
                                                    return (
                                                        <tr id={i}>
                                                            <td className="py-2 px-2 border-b border-gray-200 bg-white text-sm">
                                                                <div className="flex items-center">
                                                                    <div className="ml-3">
                                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                                            {acceso[0]}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">
                                                                        {acceso[1]}
                                                                </p>
                                                            </td>
                                                    </tr>
                                                    )})}
                                                    </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                           </div>}
                </div>
                <div className="container mx-auto my-5 p-5">
                    {this.state.hasOwnProperty('consumiciones') &&
                           <div className="container mx-auto px-4 sm:px-8 max-w-md rounded-lg shadow-md">
                                    <div className="pt-2">
                                        <div className="-mx-4 sm:-mx-8 py-4 overflow-x-auto">
                                            <div className="inline-block min-w-full overflow-hidden  rounded-lg">
                                                <h5 className="ml-4 text-xl font-semibold mb-2">Créditos torno</h5>
                                                <table className="min-w-full leading-normal m-4">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col"
                                                            className="px-2 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm  font-normal">
                                                            Días de consumición
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.consumiciones.map((consumicion, i) => {
                                                    return (
                                                        <tr id={i}>
                                                            <td className="py-2 px-2 border-b border-gray-200 bg-white text-sm">
                                                                <div className="flex items-center">
                                                                    <div className="ml-3">
                                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                                            {consumicion}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                    </tr>
                                                    )})}
                                                    </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                           </div>}
                </div>
            </Fragment>
        )
    }
}