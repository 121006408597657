import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { handleResponse, authHeader } from '../../internals/authservice/authentication.service.js';
import Popup from '../../components/popups.js';
import { api_url } from "../../internals/constants";
import {JoinSchedule} from "./JoinSchedule";
import {DetailSchedule} from "./DetailSchedule";


const API_URL = api_url;

const ClassCard = ({ classInfo, onViewDetails, onJoinLeave }) => {
  const isPastClass = moment(classInfo.fecha + ' ' + classInfo.hora_fin, 'YYYY-MM-DD HH:mm:ss').isBefore(moment());

  return (
    <div
      className={`bg-white rounded-lg shadow-lg p-4 mb-2 text-sm transition-shadow duration-300 relative overflow-hidden ${
        isPastClass ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-xl cursor-pointer'
      }`}
      onClick={() => !isPastClass && onViewDetails(classInfo)}
    >
      <div className={`absolute top-0 left-0 w-2 h-full ${
        isPastClass ? 'bg-gray-400' : (classInfo.user_inside ? 'bg-green-500' : 'bg-blue-500')
      }`}></div>
      <h3 className={`font-semibold text-lg mb-1 ${isPastClass ? 'text-gray-600' : ''}`}>{classInfo.nombre_clase}</h3>
      <p className={`${isPastClass ? 'text-gray-500' : 'text-gray-600'}`}>{classInfo.hora_inicio.substring(0, 5)} - {classInfo.hora_fin.substring(0, 5)}</p>
      {!isPastClass && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onJoinLeave(classInfo);
          }}
          className={`absolute top-4 right-2 px-3 py-1 rounded-full text-white text-xs ${
            classInfo.user_inside ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
          }`}
        >
          {classInfo.user_inside ? 'Salir' : 'Unirse'}
        </button>
      )}
      {isPastClass && (
        <span className="absolute top-2 right-2 px-3 py-1 rounded-full text-white text-xs bg-gray-400">
          Finalizada
        </span>
      )}
    </div>
  );
};

const ClassCalendar = () => {
  const [weekStart, setWeekStart] = useState(moment().startOf('isoWeek'));
  const [classes, setClasses] = useState({});
  const [popupMessage, setPopupMessage] = useState('');
  const [popupTitle, setPopupTitle] = useState('');
  const [popupError, setPopupError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    moment.locale('es');  // Set locale to Spanish
    fetchWeekClasses(weekStart);
  }, [weekStart]);

  const fetchWeekClasses = async (start) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    try {
      const response = await fetch(`${API_URL}/clases/${start.year()}/${start.month() + 1}`, requestOptions);
      const data = await handleResponse(response);
      const groupedClasses = data.reduce((acc, cls) => {
        const date = moment(cls.fecha).format('YYYY-MM-DD');
        if (!acc[date]) acc[date] = [];
        acc[date].push(cls);
        return acc;
      }, {});
      setClasses(groupedClasses);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  useEffect(() => {
    fetchWeekClasses(weekStart);
  }, [weekStart]);

  const handleWeekChange = (weeks) => {
    setWeekStart(moment(weekStart).add(weeks, 'weeks'));
  };

const handleJoinLeaveClass = async (classId, isLeaving) => {
  const action = isLeaving ? 'leave' : 'join';
  try {
    const response = await fetch(`${API_URL}/clases/${action}/${classId}`, { method: 'GET', headers: authHeader() });
    const data = await handleResponse(response);
    setJoinModalOpen(false)
    if (data.status === 1) {
      setPopupMessage(`Te has ${isLeaving ? 'desapuntado de' : 'apuntado a'} la clase correctamente`);
      setPopupTitle('Éxito');
      setPopupError(false);
    } else {
      setPopupMessage(`Error al ${isLeaving ? 'desapuntarte de' : 'apuntarte a'} la clase`);
      setPopupTitle('Error');
      setPopupError(true);
    }
    setShowPopup(true);
    fetchWeekClasses(weekStart);
  } catch (error) {
    console.error(`Error ${isLeaving ? 'leaving' : 'joining'} class:`, error);
    setPopupMessage('Ocurrió un error inesperado');
    setPopupTitle('Error');
    setPopupError(true);
    setShowPopup(true);
  }
};
  const handleViewDetails = (classInfo) => {
    setSelectedClass(classInfo);
    setDetailModalOpen(true);
  };

  const handleJoinLeaveClick = (classInfo) => {
    setSelectedClass(classInfo);
    setJoinModalOpen(true);
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <button onClick={() => handleWeekChange(-1)} className="px-2 py-1 bg-blue-500 text-white rounded">
          Semana Anterior
        </button>
        <h2 className="text-xl font-bold text-center">Semana del {weekStart.format('DD MMMM YYYY')}</h2>
        <button onClick={() => handleWeekChange(1)} className="px-2 py-1 bg-blue-500 text-white rounded">
          Semana Siguiente
        </button>
      </div>
      <div className="space-y-4">
        {[...Array(7)].map((_, index) => {
          const date = moment(weekStart).add(index, 'days');
          const dateStr = date.format('YYYY-MM-DD');
          return (
            <div key={dateStr} className="border rounded p-2">
              <h3 className="font-semibold mb-2">{date.format('dddd DD/MM')}</h3>
              {classes[dateStr] ? (
                classes[dateStr].map((classInfo) => (
                  <ClassCard
                    key={classInfo.id}
                    classInfo={classInfo}
                    onViewDetails={handleViewDetails}
                    onJoinLeave={handleJoinLeaveClick}
                  />
                ))
              ) : (
                <p className="text-gray-500">No hay clases</p>
              )}
            </div>
          );
        })}
      </div>
      {showPopup && (
        <Popup
          message={popupMessage}
          title={popupTitle}
          error={popupError}
          duration={2000}
          onClose={() => setShowPopup(false)}
        />
      )}
      <DetailSchedule
        isOpen={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        classInfo={selectedClass}
        onJoinLeave={handleJoinLeaveClick}
      />
      <JoinSchedule
        isOpen={joinModalOpen}
        onClose={() => setJoinModalOpen(false)}
        classInfo={selectedClass}
        onJoinLeave={(classId, isLeaving) => handleJoinLeaveClass(classId, isLeaving)}
      />
    </div>
  );
};

export default ClassCalendar;