import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  authenticationService,
  authHeader,
  handleResponse,
} from "../../internals/authservice/authentication.service";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/popups";
import { CheckCircleIcon } from "@heroicons/react/solid";
import * as Constants from "../../internals/constants";

const cleanIBAN = (iban) => {
  return iban.replace(/[\s-]/g, "").toUpperCase();
};

const formatIBAN = (iban) => {
  return iban.match(/.{1,4}/g)?.join(' ') || iban;
};

const validationSchema = Yup.object().shape({
  titular: Yup.string().required("El titular es requerido"),
  iban: Yup.string()
    .transform((value) => cleanIBAN(value))
    .test(
      "valid-characters",
      "El IBAN solo debe contener letras y números.\n*Espacios y guiones permitidos",
      (value) => value && /^[A-Za-z0-9]+$/.test(value)
    )
    .test(
      "valid-length",
      "El IBAN debe tener entre 15 y 34 caracteres.",
      (value) => value && value.length >= 15 && value.length <= 34
    )
    .required("El IBAN es requerido"),
});

const BillingDataEdit = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupDuration, setPopupDuration] = useState(0);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  useEffect(() => {
    if (authenticationService.currentUserValue === null) {
      navigate("/login");
    }
  }, [navigate]);

  const submitBillingData = (billingData) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", ...authHeader() },
      body: JSON.stringify(billingData),
    };

    return fetch(`${Constants.api_url}/user/billing-data/`, requestOptions)
      .then(handleResponse)
      .catch((error) => {
        console.error("Error al enviar los datos de domiciliación:", error);
        throw error;
      });
  };

  const handleSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus();

    const cleanedValues = {
      ...values,
      iban: formatIBAN(cleanIBAN(values.iban)),
    };

    submitBillingData(cleanedValues)
      .then(() => {
        setSubmissionSuccess(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setPopupDuration(5000);
        setStatus(true);
        setShowPopup(true);
      });
  };

  if (submissionSuccess) {
    return (
      <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div
            className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4"
            role="alert"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium">
                  Datos de domiciliación actualizados correctamente
                </p>
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate("/perfil")}
            className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Volver al perfil del cliente
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            Añadir o modificar datos de domiciliación
          </h2>
        </div>
        <Formik
          initialValues={{
            titular: "",
            iban: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, isValid, dirty }) => (
            <Form className="mt-8 space-y-6">
              {showPopup && (
                <Popup
                  title="Error al enviar los datos"
                  message="Ha ocurrido un error al enviar los datos de domiciliación."
                  error={true}
                  duration={popupDuration}
                  onClose={() => {}}
                />
              )}

              <div className="rounded-md shadow-sm -space-y-px-4">
                <div className="mb-4">
                  <label
                    htmlFor="titular"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Titular
                  </label>
                  <Field
                    name="titular"
                    type="text"
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {errors.titular && touched.titular && (
                    <div className="text-red-500 text-xs mt-1">
                      {errors.titular}
                    </div>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="iban"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    IBAN
                  </label>
                  <Field
                    name="iban"
                    type="text"
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {errors.iban && touched.iban && (
                    <div className="text-red-500 text-xs mt-1">
                      {errors.iban.split('\n').map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <label
                  htmlFor="terms"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Términos y Condiciones
                </label>
                <div
                  id="terms"
                  name="terms"
                  className="block w-full p-4 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm overflow-y-scroll max-h-80 bg-gray-50"
                >
                  <p><span className="text-blue-600 font-bold">1.</span> Una vez firmada esta orden, el cliente (abonado) acepta el cargo en el número de cuenta indicado de la cuota contratada a Moai Climbing.</p>

                  <p className="mt-4"><span className="text-blue-600 font-bold">2.</span> La renovación de la modalidad de abono elegido será <strong>automática</strong>, salvo que el abonado avise <strong>fehacientemente</strong> como máximo el día <strong>20 del mes en curso</strong>, anterior al mes en el que desea finalizar su contrato.</p>

                  <p className="mt-4"><span className="text-blue-600 font-bold">3.</span> Esta comunicación deberá realizarse únicamente mediante los siguientes medios: enviando un correo a <strong>info@moaiclimbing.com</strong> o mediante WhatsApp al teléfono <strong>670 10 86 06</strong>. <u>No se considerarán válidas</u> las solicitudes de baja comunicadas por otros medios no señalados en esta cláusula.</p>

                  <p className="mt-4"><span className="text-blue-600 font-bold">4.</span> La cuota será renovada y abonada automáticamente en los primeros <strong>5 días</strong> de cada mes mediante domiciliación bancaria, salvo las bajas que se hayan solicitado de acuerdo con lo indicado en el punto 3.</p>

                  <p className="mt-4"><span className="text-blue-600 font-bold">5.</span> En caso de devolución sin comunicación previa conforme a los procedimientos del punto 3, se aplicará un <strong>cargo adicional de 5€</strong> por gastos bancarios, que se cobrará dentro de los <strong>7 días</strong> siguientes a la devolución.</p>

                  <p className="mt-4"><span className="text-blue-600 font-bold">6.</span> Las cuotas abonadas no serán <strong>reembolsables</strong> ni <strong>compensables</strong> por periodos no utilizados, salvo causa de <u>fuerza mayor debidamente justificada</u>.</p>

                  <p className="mt-4"><span className="text-blue-600 font-bold">7.</span> Todos los servicios y productos adicionales deberán ser abonados en <strong>efectivo o tarjeta</strong> en el momento de la compra, sin posibilidad de ser domiciliados junto con la cuota mensual.</p>

                  <p className="mt-4"><span className="text-blue-600 font-bold">8.</span></p>
                  <p className="mt-2">&nbsp;&nbsp;&nbsp;<span className="text-blue-600 font-bold">a)</span> La causa de <u>fuerza mayor</u> incluye circunstancias imprevisibles e inevitables que alteran las condiciones del contrato. Será necesario <u>acreditar y demostrar</u> el suceso para que sea aceptado por la dirección del centro.</p>
                  <p className="mt-2">&nbsp;&nbsp;&nbsp;<span className="text-blue-600 font-bold">b)</span> Únicamente podrá interrumpirse el pago de cuotas de abonados por causas de fuerza mayor como lesión, enfermedad o fallecimiento. En estos casos, se pausará el pago durante el tiempo establecido en los apartados c y d.</p>
                  <p className="mt-2">&nbsp;&nbsp;&nbsp;<span className="text-blue-600 font-bold">c)</span> El periodo mínimo de interrupción será de <strong>1 mes natural</strong> (no se permiten pausas por días o semanas sueltas).</p>
                  <p className="mt-2">&nbsp;&nbsp;&nbsp;<span className="text-blue-600 font-bold">d)</span> El periodo máximo de interrupción será de <strong>4 meses</strong> en el caso de cuotas de abonados.</p>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isSubmitting || !(isValid && dirty)}
                  className="group relative w-full flex justify-center py-2 px-4 border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Enviar datos de domiciliación
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BillingDataEdit;
