import Card from "../../components/card.js";
import MoaiEntrena from "../../assets/images/moai-entrena.jpg"
import Instalaciones from "../../assets/images/Instalaciones.jpg"
import Escaladores from "../../assets/images/Escaladores.jpg"
import {Fragment, useEffect, useState} from "react";
import React from "react";
import Review from "../../components/Review";
import Hero from "../../components/hero";
import {fetchPlaceDetails, useGoogleMapsAPI} from "../../internals/useGoogleMapsAPI";


export default function Landing() {
    const [reviews, setReviews] = useState(null);
    const isAPILoaded = useGoogleMapsAPI('AIzaSyCRdTM8q7QC3yB54DOlgixBSP8mmBSv8AQ');

    useEffect(() => {
        if (isAPILoaded) {
            fetchPlaceDetails('ChIJbVKWM7ZfZg0Rg9eK9TmZSN8', handlePlaceDetailsFetched);
        }
    }, [isAPILoaded]);

    const handlePlaceDetailsFetched = (place) => {
        const reviews = place.reviews.filter((e) => e.rating >= 4);
        shuffleArray(reviews);
        setReviews(reviews)
    };

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    }

    return (
        <Fragment>
            <div className="bg-gray-100">
             <Hero show_logo boton={{button_text: "Instalaciones en Albacete", click_dir: '/#'}} />
             <div className="flex flex-wrap justify-center content-center gap-x-32 gap-y-12 mt-4 pb-20">
                            <Card
                                click_dir={'/blog/instalaciones'}
                                card_title={'Un nuevo concepto de entrenar\n'}
                                card_img={Instalaciones}
                                card_text={"Toda la superficie escalable ha sido construida por personal cualificado, teniendo en cuenta toda la normativa de seguridad existente y cuidando cada detalle para que la estancia en nuestras instalaciones sea del máximo agrado.\n"}
                                button_text={"Visitar instalaciones"}
                            />
                                 <Card
                                click_dir={'/blog/clases-de-escalada'}
                                card_title={'Comunidad Moai'}
                                card_img={MoaiEntrena}
                                card_text={ "La Comunidad Moai es un servicio exclusivo, innovador y personal de Moai Climbing. En el momento de darte de alta en el rocódromo se te creará un perfil en el servicio, para que puedas disfrutar de las asombrosas ventajas."}
                                button_text={"Conocer más"}
                            />
                                 <Card
                                click_dir={'/calendario'}
                                card_title={'Grupos de Entrenamiento'}
                                card_img={Escaladores}
                                card_text={"El iniciarte en la escalada no será un problema, porque con los grupos de entrenamiento podrás empezar desde cero. Si ya sabes escalar no te preocupes, también hay grupos para ti.\n" +
                                "¡¡Y no necesitas ningún material para empezar!!"}
                                button_text={"Apuntate a clase!"}
                            />

            </div>
            <div id="google"/>
            <Review reviews={reviews}/>
            </div>
        </Fragment>
    )
}
