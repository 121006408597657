import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';

export function DetailSchedule({ isOpen, onClose, classInfo, onJoinLeave }) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  if (!classInfo || !isOpen) return null;
  const isPastClass = moment(classInfo.fecha + ' ' + classInfo.hora_fin, 'YYYY-MM-DD HH:mm:ss').isBefore(moment());
  const formattedDate = moment(classInfo.fecha).locale('es').format('dddd DD MMMM');

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-75 flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {classInfo.nombre_clase}
          </h3>
          <button
            onClick={onClose}
            className="absolute top-0 right-0 mt-4 mr-4 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mt-2">
          <p className="text-sm text-gray-500 capitalize"><b>Fecha:</b> {formattedDate} </p>
          <p className="text-sm text-gray-500 capitalize"><b>Hora: </b>{classInfo.hora_inicio.substring(0, 5)}</p>
          <p className="text-sm text-gray-500"><b>Apuntados:</b> {classInfo.apuntados} / {classInfo.aforo}</p>
          <p className="text-sm text-gray-500"><b>Coste:</b> {classInfo.coste_creditos} créditos</p>
        </div>
        <div className="mt-4">
          {!isPastClass && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClose()
            onJoinLeave(classInfo);
          }}
          className={`right-2 px-3 p-2 px-4 rounded-full text-white text-md mr-2 ${
            classInfo.user_inside ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
          }`}
        >
          {classInfo.user_inside ? 'Salir' : 'Unirse'}
        </button>
      )}
          <button
            type="button"
            className="font-medium p-2 px-4 text-md rounded-full text-white bg-gray-500 hover:bg-gray-600 mr-2"
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
}