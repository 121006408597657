import React from 'react'
import ContentLoader from 'react-content-loader'


const ArticleLoader = props => (
  <ContentLoader
    width={850}
    height={1500}
    speed={5}
    style={{margin: 'auto'}}
    viewBox="0 0 850 1500"
    backgroundColor="#eaeced"
    foregroundColor="#ffffff"
    {...props}
  >
    <rect x="42" y="57" rx="4" ry="4" width="417" height="29" />
    <rect x="42" y="105" rx="4" ry="4" width="67" height="15" />
    <rect x="127" y="105" rx="4" ry="4" width="147" height="15" />
    <circle cx="739" cy="109" r="9" />
    <circle cx="765" cy="109" r="9" />
    <rect x="217" y="157" rx="4" ry="4" width="433" height="291" />
    <rect x="359" y="457" rx="4" ry="4" width="150" height="10" />
    <rect x="48" y="515" rx="4" ry="4" width="720" height="15" />
    <rect x="49" y="547" rx="4" ry="4" width="598" height="15" />
    <rect x="48" y="581" rx="4" ry="4" width="720" height="15" />
    <rect x="49" y="612" rx="4" ry="4" width="520" height="15" />
    <rect x="48" y="652" rx="4" ry="4" width="720" height="15" />
    <rect x="48" y="684" rx="4" ry="4" width="598" height="15" />
    <rect x="48" y="718" rx="4" ry="4" width="720" height="15" />
    <rect x="49" y="748" rx="4" ry="4" width="419" height="15" />
    <rect x="359" y="457" rx="4" ry="4" width="150" height="10" />
    <rect x="48" y="780" rx="4" ry="4" width="720" height="15" />
    <rect x="49" y="830" rx="4" ry="4" width="720" height="4" />
    <rect x="50" y="860" rx="4" ry="4" width="720" height="400" />
    <rect x="49" y="1400" rx="4" ry="4" width="598" height="15" />
    <rect x="48" y="1300" rx="4" ry="4" width="720" height="15" />
    <rect x="49" y="1350" rx="4" ry="4" width="520" height="15" />


  </ContentLoader>
)

ArticleLoader.metadata = {
  name: 'Sridhar Easwaran',
  github: 'sridhareaswaran',
  description: 'Article or News',
  filename: 'ArticleLoader',
}

export default ArticleLoader