import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { authenticationService, authHeader, handleResponse } from "../../internals/authservice/authentication.service";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/popups";
import { CheckCircleIcon } from "@heroicons/react/solid";
import * as Constants from "../../internals/constants";

const ChildRegister = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupDuration, setPopupDuration] = useState(0);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const parentId = authenticationService.currentUserValue.id;

  useEffect(() => {
    if (authenticationService.currentUserValue === null) {
      navigate("/login");
    }
  }, [navigate]);

  const crearHijo = (childData) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify({ ...childData, parentId }),
    };

    return fetch(`${Constants.api_url}/user/create-child/`, requestOptions)
      .then(handleResponse)
      .catch((error) => {
          console.error("Error en la creación del hijo:", error);
          throw error;
      });
  };

  const handleSubmit = (values, { setStatus, setSubmitting }) => {
    setStatus();

    crearHijo(values)
      .then(() => {
        setRegistrationSuccess(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setPopupDuration(5000);
        setStatus(true);
        setShowPopup(true);
      });
  };

  if (registrationSuccess) {
    return (
      <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <div
              className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4"
              role="alert"
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium">Registro de hijo exitoso</p>
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate("/perfil")}
              className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Volver al perfil del cliente
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            Registrar Hijo
          </h2>
        </div>
        <Formik
          initialValues={{
            nombre: "",
            apellidos: "",
            birthday: "",
            genero: "NO",
            dni: "",
          }}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().required("Nombre requerido"),
            apellidos: Yup.string().required("Apellidos requeridos"),
            birthday: Yup.date().required("Fecha de nacimiento requerida"),
            genero: Yup.string().required("Género requerido"),
            dni: Yup.string().required("DNI requerido"),
          })}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, isValid, dirty }) => (
            <Form className="mt-8 space-y-6">
              {showPopup && (
                <Popup
                  title="Error en el registro"
                  message="Ha ocurrido un error durante el registro."
                  error={true}
                  duration={popupDuration}
                  onClose={() => {}}
                />
              )}

              <div className="rounded-md shadow-sm -space-y-px-4">
                <div className="mb-4">
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Nombre
                  </label>
                  <Field
                    name="nombre"
                    type="text"
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {errors.nombre && touched.nombre && (
                    <div className="text-red-500 text-xs mt-1">
                      {errors.nombre}
                    </div>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="apellidos"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Apellidos
                  </label>
                  <Field
                    name="apellidos"
                    type="text"
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {errors.apellidos && touched.apellidos && (
                    <div className="text-red-500 text-xs mt-1">
                      {errors.apellidos}
                    </div>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="birthday"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Fecha de Nacimiento
                  </label>
                  <Field
                    name="birthday"
                    type="date"
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {errors.birthday && touched.birthday && (
                    <div className="text-red-500 text-xs mt-1">
                      {errors.birthday}
                    </div>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="genero"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Género
                  </label>
                  <Field
                    as="select"
                    name="genero"
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    <option value="NO">Indeterminado</option>
                    <option value="HO">Masculino</option>
                    <option value="MU">Femenino</option>
                  </Field>
                  {errors.genero && touched.genero && (
                    <div className="text-red-500 text-xs mt-1">
                      {errors.genero}
                    </div>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="dni"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    DNI
                  </label>
                  <Field
                    name="dni"
                    type="text"
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {errors.dni && touched.dni && (
                    <div className="text-red-500 text-xs mt-1">{errors.dni}</div>
                  )}
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting || !(isValid && dirty)}
                  className="group relative w-full flex justify-center py-2 px-4 border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Añadir Hijo
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChildRegister;
