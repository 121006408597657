import React, { useState, useEffect, Fragment } from 'react';
import * as Constants from "../../internals/constants";
import { authenticationService, authHeader, handleResponse } from "../../internals/authservice/authentication.service";
import { useNavigate } from 'react-router-dom';
import Escaladores from "../../assets/images/Escaladores.jpg";

function Capitalize(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '-';
}

function Format(str, def = '-') {
    return str ? str : def;
}

function Gender(str) {
    if (!str) return '-';
    if (str === 'NO') return '-';
    return str === 'HO' ? 'Hombre' : 'Mujer';
}

const Profile = () => {
    const [profileData, setProfileData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const requestOptions = { method: 'GET', headers: authHeader() };
        fetch(`${Constants.api_url}/user/profile`, requestOptions)
            .then(handleResponse)
            .then(data => setProfileData(data));
    }, []);

    const handleAddChild = () => {
        navigate(`/perfil/crear-hijo/`);
    }

    const handleBillingData = () => {
        navigate(`/perfil/domiciliacion/`);
    }

    const renderPrivateField = (label, value) => (
        <div className="grid grid-cols-2">
            <div className="px-4 py-2 font-semibold">{label}</div>
            <div className="px-4 py-2">{value}</div>
        </div>
    );

    const renderPrivateData = () => (
        <div className="bg-white p-3 shadow-sm rounded-sm">
            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                <span className="text-green-500">
                    <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                </span>
                <span className="tracking-wide">Datos privados del Cliente</span>
            </div>
            <div className="text-gray-700">
                <div className="grid md:grid-cols-2 text-sm">
                    {renderPrivateField("Nombre", Capitalize(profileData.nombre))}
                    {renderPrivateField("Apellidos", Capitalize(profileData.apellidos))}
                    {renderPrivateField("DNI/NIF", Format(profileData.dni))}
                    {renderPrivateField("Género", Gender(profileData.genero))}
                    {renderPrivateField("Teléfono", Format(profileData.movil))}
                    {renderPrivateField("Dirección", Format(profileData.direccion))}
                    {renderPrivateField("Email", <a className="text-blue-800">{Format(profileData.email)}</a>)}
                    {renderPrivateField("Cumpleaños", Format(profileData.birthday))}
                </div>
                <div className="flex justify-center mt-6">
                    <button
                        onClick={handleBillingData}
                        className="text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline font-bold py-2 px-4 rounded">
                        Enviar datos de domiciliación
                    </button>
                </div>
            </div>
            <button
                className="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">
                ¿Quieres actualizar algún dato? Contacta con recepción.
            </button>
        </div>
    );

    const renderChildren = () => (
        <div className="bg-white p-3 shadow-sm rounded-sm mt-5">
            <div className="flex items-center justify-between mb-3">
                <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                    <span className="text-green-500">
                        <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                    </span>
                    <span className="tracking-wide">Hijos Asociados</span>
                </div>
                <button
                    onClick={handleAddChild}
                    className="text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline font-bold py-2 px-4 rounded">
                    Añadir Hijo
                </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {profileData.hijos && profileData.hijos.length > 0 ? (
                    profileData.hijos.map((hijo) => (
                        <div key={hijo.id} className="relative bg-white rounded shadow-md p-4">
                            <div className={`absolute top-0 left-0 right-0 h-2 ${hijo.genero === 'HO' ? 'bg-blue-400' : hijo.genero === 'MU' ? 'bg-pink-400' : 'bg-gray-400'} rounded-t-md`}></div>
                            <div className="flex flex-col items-center pt-4">
                                <svg className="h-8 w-8 text-gray-500 mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                                <h2 className="text-sm font-semibold">{hijo.nombre} {hijo.apellidos}</h2>
                                <div className="text-xs text-gray-600 mb-2">
                                    <p className="flex items-center">{hijo.dni || 'N/A'}</p>
                                    <p className="flex items-center">{hijo.birthday || 'N/A'}</p>
                                    <p className="flex items-center">Sexo: {hijo.genero === 'HO' ? 'Hombre' : hijo.genero === 'MU' ? 'Mujer' : 'Indeterminado'}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No hay hijos asociados.</p>
                )}
            </div>
        </div>
    );

    const renderMemberships = () => (
        <div className="bg-white p-3 shadow-sm rounded-sm">
            <div className="grid grid-cols-1">
                <div>
                    <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                        <span className="text-green-500">
                            <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                        </span>
                        <span className="tracking-wide">Últimas membresías</span>
                    </div>
                    <ul className="list-inside space-y-2">
                        {profileData.membresias && profileData.membresias.map((membresia) => (
                            <li key={membresia.id}>
                                <div className="text-teal-600">Membresía</div>
                                <div className="text-gray-500 text-md">{membresia.fecha_inicio} - {membresia.fecha_fin}</div>
                                <a className="text-green-600 text-sm" href={`${Constants.api_url}/invoice/${membresia.id}?tk=${authenticationService.currentUserValue}`}>Consultar factura</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            <div className="container mx-auto my-5 p-5">
                {profileData.hasOwnProperty('email') && (
                    <div className="md:flex no-wrap md:-mx-2">
                        <div className="w-full md:w-3/12 md:mx-2">
                            <div className="bg-white p-3 border-t-4 border-green-400 rounded-sm">
                                <div className="image overflow-hidden rounded-md">
                                    <img src={Escaladores} alt="Perfil" />
                                </div>
                                <h1 className="text-gray-900 font-bold text-xl leading-8 my-1 overflow-auto">
                                    {Format(profileData.nombre)} ({Capitalize(profileData.username)})
                                </h1>
                                <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                                    <li className="flex items-center py-3">
                                        <span>Membresía Activa</span>
                                        <span className="ml-auto">
                                            <span className="bg-green-500 py-1 px-2 rounded text-white text-sm">Activa</span>
                                        </span>
                                    </li>
                                    <li className="flex items-center py-3">
                                        <span>Miembro desde</span>
                                        <span className="ml-auto">{profileData.fecha_alta}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full md:w-9/12 mx-2">
                            {renderPrivateData()}
                            {renderChildren()}
                            {renderMemberships()}
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Profile;