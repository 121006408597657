import React, { useEffect, useState } from "react";
import {StreamField} from "./StreamField";
import { api_url } from "../constants";
import {useParams} from 'react-router-dom'
import ArticleLoader from "../../components/loader";

import '../../assets/css/blog.css';
async function fetchPageData(slug) {
    if (!slug) return null;
    const response = await fetch(`${api_url}/api/v2/pages/${slug}`);
    if (!response.ok) {
        return {id: '1233', title: 'Contenido no encontrado', body: [{type: 'p', value: 'El contenido ya no existe, o quizás nunca existió. Disculpa las molestias.'}]}
    }
    return response.json();
}

export default function CmsContent() {
    const [pageData, setPageData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        fetchPageData(slug).then(data => {
            if (data) setPageData(data);
        });
    }, [slug]);

    if (!pageData) {
        return <div className="container sm mx-auto"><ArticleLoader /></div>;
    }

    return <StreamField value={pageData} />;
}