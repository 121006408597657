import { useState, useEffect } from 'react';

export function useGoogleMapsAPI(apiKey) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=${apiKey}`;
        script.async = true;
        script.onload = () => {
            setIsLoaded(true);
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [apiKey]);

    return isLoaded;
}

export function fetchPlaceDetails(placeId, onPlaceDetailsFetched) {
    if (window.google) {
        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        const request = { placeId };
        service.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                onPlaceDetailsFetched(place);
            }
        });
    }
}