import React from "react";
import CardReview from "./cardreview";


function Review(props) {

    return (
        <div>
            <div>
                <span className="flex justify-center font-bold text-2xl mb-8 negro-pero-no-mucho text-center">Algunas de nuestras reviews en Google</span>
                <div className="flex flex-wrap justify-center content-center gap-x-8 gap-y-12 mt-4 pb-20">
                    {props.reviews && props.reviews.slice(0, 4).map((review) => {
                        return (<ul className="flex justify-center">
                            <CardReview review={review}/>
                        </ul>)
                    })}

                </div>
            </div>

        </div>
    )

}

export default Review