import { Button } from "./button";
import {useHistory, useNavigate} from "react-router-dom";
export function Card({
                                 card_title,
                                 card_text,
                                 alignment=null,
                                 click_dir = null,
                                 button_text = null,
                                 card_img = null,
                                 boton = {}
                             }) {
    const navigate = useNavigate();

    const handleClick = () => {
        if (click_dir || (boton && boton.click_dir)) {
            navigate(click_dir || boton.click_dir);
        }
    };

    const textAlignment = alignment === null ? 'text-justify' : `text-${alignment}`
    const buttonText = button_text || (boton && boton.button_text);
    const imgUrl = card_img?.md?.url || card_img;

    return (
        <div className="w-80">
            {imgUrl && (
                <img
                    src={imgUrl}
                    className="rounded-lg shadow-xl mb-4 m-auto block"
                    alt=""
                />
            )}

            <div className="text-center">
                <h5 className="text-xl font-semibold mb-2">{card_title}</h5>

                <p className={`mb-4 ${textAlignment}`} dangerouslySetInnerHTML={{ __html: card_text }} />

                {buttonText && <Button text={buttonText} onClick={handleClick} />}
            </div>
        </div>
    );
}



export default Card
