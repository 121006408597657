import * as Icons from "@heroicons/react/outline";


const botones = {
    "Right" : <Icons.ArrowRightIcon/>,
    "Left": <Icons.ArrowLeftIcon/>,
    "Down": <Icons.ChevronDownIcon/>
}



export function ButtonIcon(props) {
    return (<button className="flex items-center px-2 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md hover:ring-4 hover:ring-offset-4 dark:bg-gray-800  ring-blue-500 hover:bg-blue-500 dark:hover:bg-gray-700  "
        onClick={props.onClick}
    >
        <span className="w-5 h-5 mx-1">{botones[props.icono]}</span>
        <span className="mx-1 text-sm font-normal">{props.text}</span>
    </button>)
    
}

export function Button(props) {
    return (
        <button onClick={props.onClick} className="px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 transform bg-blue-600 rounded-md dark:bg-gray-800 hover:ring-4 hover:ring-offset-4 hover:bg-blue-500 dark:hover:bg-gray-700 ">
        {props.text}
    </button>
    )
}
