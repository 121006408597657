import {Link} from "react-router-dom";
import Hold from "../../assets/images/holds/hold.svg";
import {useEffect, useState} from "react";
import {fetchPlaceDetails, useGoogleMapsAPI} from "../useGoogleMapsAPI";

export default function Footer() {
    const isAPILoaded = useGoogleMapsAPI('AIzaSyCRdTM8q7QC3yB54DOlgixBSP8mmBSv8AQ');
    const [place, setPlaceInfo] = useState(null)

    useEffect(() => {
        if (isAPILoaded) {
            fetchPlaceDetails('ChIJbVKWM7ZfZg0Rg9eK9TmZSN8', populateInfo);
        }
    }, [isAPILoaded]);

    const populateInfo = (place) => {
        setPlaceInfo(place)
    }

    return (
        <div className="pt-12 bg-gray-100">
            <footer id="footer" className="relative z-10">
                <div className=" border-t border-b border-gray-200 py-16">
                    <div className="mx-auto container px-4 xl:px-12 2xl:px-4">
                        <div className="flex flex-wrap justify-between gap-y-10">

                                <div className="flex-auto px-6 space-y-4">
                                    <ul>
                                        <li>
                                            <Link to="/blog/tarifas">
                                                <a className="text-sm leading-none hover:text-brand text-gray-800">
                                                    Tarifas</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/blog/escuela-infantil">
                                                <a className="text-sm leading-none hover:text-brand text-gray-800">
                                                    Escuela Infantil</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/blog/clases-de-escalada">
                                                <a className="text-sm leading-none hover:text-brand text-gray-800">Clases de Escalada</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/blog/instalaciones">
                                                <a className="text-sm  leading-none hover:text-brand text-gray-800">Instalaciones</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="px-6 w-full lg:w-1/2 justify-self-center flex columns-1">
                                    {
                                        place && (
                                            <div className="text-md text-gray-800">
                                                <div className="text-sm"><span className="font-bold">Teléfono:</span> {place?.formatted_phone_number}</div>
                                                <div className="text-sm mt-2"><span className="font-bold">Dirección:</span> {place?.formatted_address}</div>
                                                <div className="text-sm mt-2"><span className="font-bold">Horarios:</span> {place?.opening_hours?.weekday_text?.map(day => <div>{day}</div>)}</div>
                                            </div>
                                        )
                                    }
                                </div>

                            <div className="">
                                <a className="px-6 text-justify font-light flex" href="https://instagram.com/moai_climbing" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path style={{fill:"#323434"}} d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                                    <span className="ml-4 mt-1 text-sm leading-none hover:text-brand dark:hover:text-brand text-gray-800">Nuestro Instagram</span>
                                </a>
                                <img src={Hold} className="m-8" alt="presa" style={{width:'48px', height:'48px'}}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pb-8 flex flex-col justify-center items-center">
                    <div className="">
                        <div className="w-full px-6 pt-8 text-justify font-thin">
                            Un entrenamiento diferente,  gimnasio en Albacete. Mejor rocódromo de escalada en Albacete. Aprende a escalar, clases de escalada, actividades únicas y divertidas para niños.
                        </div>
                    </div>
                    <p className="mt-6 text-xs lg:text-sm leading-none text-gray-500">2022 - Moai Climbing. Todos los derechos reservados.</p>
                </div>
            </footer>
        </div>
    );
};

