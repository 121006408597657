import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { authenticationService } from './internals/authservice/authentication.service';
import ClassCalendar from './pages/calendar/Calendar';
import TopBar from './internals/nav/TopBar';
import { PrivateRoute } from './internals/authservice/privateroute.comp';
import Landing from './pages/landing/Landing';
import Profile from "./pages/user/Profile";
import { Accesos } from "./pages/user/Accesos";
import Footer from "./internals/nav/Footer";
import CmsContent from "./internals/cms/CmsContent";
import LoginPage from "./pages/Login";
import { Register } from "./pages/user/Register";
import ChildRegister from "./pages/user/ChildRegister";
import BillingDataEdit from "./pages/user/BillingDataEdit";

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const subscription = authenticationService.currentUser.subscribe(x => {
      setCurrentUser(x);
    });
    authenticationService.refresh();

    return () => subscription.unsubscribe();
  }, []);

  return (
    <Router>
      <TopBar />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registro" element={<Register />} />
        <Route path="/blog/:slug" element={<CmsContent />} />
        <Route element={<PrivateRoute />}>
          <Route path="/calendario" element={<ClassCalendar />} />
          <Route path="/perfil" element={<Profile />} />
          <Route path="/accesos" element={<Accesos />} />
          <Route path="/perfil/crear-hijo" element={<ChildRegister />} />
          <Route path="/perfil/domiciliacion" element={<BillingDataEdit />} />
        </Route>
        <Route path="/" element={<Landing />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export { App };
