import React, { useRef, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from "react-router-dom";
import { LockClosedIcon, CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import { authenticationService } from '../internals/authservice/authentication.service';
import logo from '../assets/images/logo.png';
import * as constants from "../internals/constants";
import Popup from "../components/popups";

export default function LoginPage() {
    const popupRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [popupError, setPopupError] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registeredEmail, setRegisteredEmail] = useState('');

    useEffect(() => {
        if (authenticationService.currentUserValue) {
            navigate('/');
        }

        // Check if there's registration success info in the location state
        if (location.state && location.state.registrationSuccess) {
            setRegistrationSuccess(true);
            setRegisteredEmail(location.state.email);
        }

        // Check for email confirmation query parameter
        const params = new URLSearchParams(location.search);
        const emailConfirmed = params.get('email-confirmed');

        if (emailConfirmed === 'true') {
            setPopupTitle('Cuenta Activada');
            setPopupMessage('Tu cuenta ha sido activada exitosamente. Ahora puedes iniciar sesión.');
            setPopupError(false);
            setShowPopup(true);
        } else if (emailConfirmed === 'false') {
            setPopupTitle('Error de Activación');
            setPopupMessage('Hubo un problema al activar tu cuenta. Por favor, intenta nuevamente o contacta con recepción.');
            setPopupError(true);
            setShowPopup(true);
        }
    }, [navigate, location]);

    return (
        <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img
                        className="mx-auto h-30 w-auto mb-4"
                        src={logo}
                        alt="Workflow"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Iniciar Sesión
                    </h2>
                </div>
                <Formik
                    initialValues={{
                        username: '',
                        password: ''
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().required('Nombre de usuario requerido'),
                        password: Yup.string().required('Contraseña requerida')
                    })}
                    onSubmit={({ username, password }, { setSubmitting }) => {
                        authenticationService.login(username, password)
                            .then(
                                () => {
                                    navigate('/');
                                },
                                error => {
                                    setSubmitting(false);
                                    setPopupError(true)
                                    setShowPopup(true)
                                    setPopupTitle('Fallo en el inicio de sesión')
                                    setPopupMessage('Las credenciales no son correctas. Puedes reestablacer tu contraseña pulsando en ¿Olvidaste tu contraseña? o contactar con recepción si algo va mal.')
                                }
                            );
                    }}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form className="mt-8 space-y-6">
                            {showPopup && (
                                <div className={`rounded-md ${popupError ? 'bg-red-50' : 'bg-green-50'} p-4 mb-4`}>
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            {popupError ? (
                                                <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                            ) : (
                                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                            )}
                                        </div>
                                        <div className="ml-3">
                                            <h3 className={`text-sm font-medium ${popupError ? 'text-red-800' : 'text-green-800'}`}>
                                                {popupTitle}
                                            </h3>
                                            <div className={`mt-2 text-sm ${popupError ? 'text-red-700' : 'text-green-700'}`}>
                                                <p>{popupMessage}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="rounded-md shadow-sm -space-y-px-4">
                                <div className="mb-4">
                                    <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                                        Correo electrónico
                                    </label>
                                    <Field
                                        id="username"
                                        name="username"
                                        type="text"
                                        className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        placeholder="adam.ondra@gmail.com"
                                    />
                                    {errors.username && touched.username && <div className="text-red-500 text-xs mt-1">{errors.username}</div>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                                        Contraseña
                                    </label>
                                    <Field
                                        id="password"
                                        name="password"
                                        type="password"
                                        className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        placeholder="*********"
                                    />
                                    {errors.password && touched.password && <div className="text-red-500 text-xs mt-1">{errors.password}</div>}
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                        <LockClosedIcon className="h-5 w-5 text-blue-500 group-hover:text-blue-400" aria-hidden="true" />
                                    </span>
                                    Iniciar Sesión
                                </button>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="text-sm">
                                    <a href={`${constants.api_url}/cuenta/password_reset/`} className="font-medium text-blue-600 hover:text-blue-500">
                                        ¿Olvidaste tu contraseña?
                                    </a>
                                </div>
                                <div className="text-sm">
                                    <a href="/registro" className="font-medium text-blue-600 hover:text-blue-500">
                                        ¿No tienes cuenta? Regístrate
                                    </a>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}