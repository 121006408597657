export const TermsAndConditions = () => {
    return (
        <>
            <h2>1. Ámbito</h2>
            <p>Estas Condiciones Generales y normas de uso, regulan la relación entre el usuario o/y representante legal
                y C.D. MOAI CLIMBING, durante su estancia en las instalaciones y/o durante la participación en cualquier
                actividad organizada por la entidad. Las instalaciones de Moai Climbing comprenden:</p>
            <ul>
                <li className="list-disc ml-2">Muros de escalada en los que se requiere cuerda de aseguramiento.</li>
                <li className="list-disc ml-2">Zonas de bloque.</li>
                <li className="list-disc ml-2">Zona de aparatos de fitness y específicos.</li>
                <li className="list-disc ml-2">Aulas.</li>
                <li className="list-disc ml-2">Salas multiusos.</li>
                <li className="list-disc ml-2">Vestuarios.</li>
                <li className="list-disc ml-2">Zonas comunes.</li>
                <li className="list-disc ml-2">Recepción.</li>
            </ul>

            <h2 className="mt-2">2. Consentimiento informado</h2>
            <p>El usuario y/o su representante legal reconoce:</p>
            <ol type="a">
                <li className="list-disc">Entender que la escalada en las diferentes modalidades que se puede practicar en Moai Climbing es
                    una activad deportiva no exenta de riesgo que, aunque se puede reducir en gran medida con un
                    correcto uso de la instalación, adoptando las medidas de seguridad oportunas y siguiendo estas
                    Normas de Uso, existen riesgos inherentes a la actividad e imponderables.
                </li>
                <li className="list-disc ml-2">Haber recibido información y formación completa y suficiente sobre las características de la
                    actividad y haber leído las presentes Condiciones Generales y Normas de Uso.
                </li>
                <li className="list-disc ml-2">Estar en condiciones físicas y mentales adecuadas para participar en la actividad, y no tener
                    enfermedad o limitación física que se lo impida, o que pueda desencadenarse durante su desarrollo.
                </li>
                <li className="list-disc ml-2">Conocer y aceptar que mi participación implica riesgo para mi integridad física y la de otros, lo
                    que incluye, pero no se limita a: lesiones, enfermedades, disminuciones sensoriales, pérdida de
                    partes del cuerpo, incapacidades permanentes, o incluso la muerte.
                </li>
                <li className="list-disc ml-2">Entender que, debido a su ubicación geográfica y a las condiciones en que se pueden desarrollar
                    algunas de las actividades organizadas por Moai Climbing, pueden verse afectadas por situaciones
                    adversas como climatología, desastres naturales, inestabilidad política o social, y otros similares,
                    que podrían comportar perjuicios para el usuario y sus bienes, o la modificación de la actividad, la
                    suspensión de algunas de las actividades o etapas previstas, e incluso la anulación.
                </li>
                <li className="list-disc ml-2">Conocer y aceptar que los riesgos de la participación en la actividad no pueden ser totalmente
                    controlados, ni por el propio usuario ni por Moai Climbing, asumiendo las posibles consecuencias
                    negativas que se puedan derivar de la actividad siempre que Moai Climbing no haya actuado con la
                    diligencia debida, mala fe manifiesta o negligencia atendiendo a las circunstancias.
                </li>
                <li className="list-disc ml-2">En caso de que, bajo la tutela del usuario, participe un menor de edad, las asunciones y
                    declaraciones de este documento incluyen al menor.
                </li>
            </ol>

            <h2 className="mt-2">3. Horarios de Apertura y Restricciones de Acceso</h2>
            <p>Los horarios de apertura Moai Climbing se recogen en el ANEXO I. Horarios de Apertura de Moai
                Climbing</p>

            <h2 className="mt-2">4. Acceso a las Instalaciones</h2>
            <p>Para acceder a las instalaciones de Moai Climbing es de obligatorio cumplimiento la inscripción en la
                recepción. Moai Climbing podrá expedir carnés de socio para sus socios o usuarios autorizados que
                identificará al poseedor autorizado, la categoría de abono y el número de cliente del socio. El carnet
                es un elemento identificador del socio por lo que deberá ser tratado con la diligencia pertinente. En el
                caso de pérdida o robo del carné de socio:</p>
            <ul>
                <li className="list-disc ml-2">El titular deberá notificarlo Moai Climbing a la mayor brevedad a fin de expedir un nuevo carné y
                    actualizar la cuenta del usuario.
                </li>
                <li className="list-disc ml-2">El poseedor es responsable de todos los cargos efectuados con el carné antes de la notificación de
                    la pérdida o robo del carné de socio.
                </li>
                <li className="list-disc ml-2">La sustitución o reemplazo del carnet de socio tiene un coste de dos euros (2,00€).</li>
            </ul>
            <p>El usuario declara y garantiza que la información personal son correctos y veraces y adquiere el
                compromiso de mantener los mismos actualizados en todo momento.</p>

            <h2 className="mt-2">5. Menores de edad y personas con discapacidad</h2>
            <p>Los menores de edad e incapacitados necesitan unos requisitos previos para acceder a la instalación.</p>
            <ul>
                <li className="list-disc ml-2">Menores de entre 16 y 18 años tan sólo podrán acceder a las instalaciones de Moai Climbing previo
                    consentimiento expreso de, al menos, un representante legal.
                </li>
                <li className="list-disc ml-2">Los menores de 15 años sólo podrán acceder a la instalación previo consentimiento expreso de, al
                    menos un representante legal que deberá permanecer en la instalación, a excepción de las actividades
                    guiadas.
                </li>
                <li className="list-disc ml-2">Menores de 12 años sólo podrán acceder a la instalación previo consentimiento expreso de, al menos
                    un representante legal que deberá permanecer junto al menor en la instalación, a excepción de las
                    actividades guiadas. El representante legal acepta que el menor utilice las instalaciones y/o
                    participe en la actividad de Moai Climbing conforme a las presentes Normas de Uso.
                </li>
            </ul>
            <p><strong>Excepción:</strong> Todos los menores de edad que accedan a la instalación con una actividad
                guiada por Moai Climbing estarán a cargo del responsable de la actividad durante la duración de la
                misma.</p>
            <p>Las actividades para jóvenes, y las clases/eventos/competiciones para jóvenes pueden estar sujetos a
                restricciones de edad. Y la participación en las mismas estará supeditada al consentimiento expreso de,
                al menos, un tutor legal. Moai Climbing podrá denegar la inscripción en actividades, la entrada a las
                instalaciones o limitar el acceso a menores por motivos de seguridad o comportamiento inadecuado.</p>

            <h2 className="mt-2">6. Precios y condiciones</h2>
            <p>Los precios se recogen en el Anexo II. Precios. Todos los precios y las condiciones están disponibles en
                nuestra la página web www.moailcimbing.com y en las instalaciones. En el caso de existir diferencias,
                será vinculante la última versión publicada.</p>

            <h2 className="mt-2">7. Normas generales de uso</h2>
            <ul>
                <li className="list-disc ml-2">Es obligatorio registrarse en la recepción al entrar en Moai Climbing y, la salida devolver
                    cualquier material alquilado o recibido en préstamo.
                </li>
                <li className="list-disc ml-2">Se debe actuar con precaución, sentido común y cortesía en todo momento.</li>
                <li className="list-disc ml-2">Fumar y consumir drogas está prohibido tanto en el interior como el exterior de Moai Climbing. Se
                    negará la entrada de cualquiera persona que muestre cualquier signo de intoxicación por alcohol o
                    drogas.
                </li>
                <li className="list-disc ml-2">Queda prohibido el acceso al interior de las muros de escalada, zonas precintadas, zonas privadas y
                    las zonas marcadas como "sólo personal autorizado" y "entrada prohibida".
                </li>
                <li className="list-disc ml-2">En caso de necesitar asistencia, acudir al personal Moai Climbing.</li>
                <li className="list-disc ml-2">Sólo el personal de Moai Climbing o personas autorizadas por Moai Climbing pueden impartir clases.
                    Cualquier otra persona que esté realizando clases remuneradas se le pedirá que abandone las
                    instalaciones de Moai Climbing y se le puede revocar su derecho de entrada sin compensación.
                </li>
                <li className="list-disc ml-2">Cualquier problema con los muros de escalada, los equipamientos, la instalación o el mal
                    comportamiento de algún usuario deberá ser puesto en conocimiento directamente al personal de Moai
                    Climbing
                </li>
                <li className="list-disc ml-2">Se debe cumplir en todo momento la señalización de buenas prácticas, salud y seguridad expuesta en
                    las instalaciones de Moai Climbing.
                </li>
                <li className="list-disc ml-2">El usuario está informado de la existencia de un circuito de video vigilancia en todo el centro,
                    excepto en vestuarios.
                </li>
            </ul>

            <h2 className="mt-2">8. Normas de escalada en bloque o Boulder</h2>
            <p>Las normas específicas de esta modalidad de escalada se recogen en el Anexo V. Normas de escalada en
                bloque o Boulder.</p>

            <h2 className="mt-2">9. Normas específicas para grupos y cursos</h2>
            <p>Las normas específicas de aplicables a los grupos y cursos se recogen en el Anexo VI. Normas específicas
                para grupos y cursos.</p>

            <h2 className="mt-2">10. Pertenencias personales</h2>
            <ul>
                <li className="list-disc ml-2">Moai Climbing no se hace responsable de los objetos que puedan extraviarse o sustraerse en
                    cualquiera de las instalaciones.
                </li>
                <li className="list-disc ml-2">Moai Climbing recomienda a los usuarios que no lleven a las instalaciones ningún objeto de valor.
                </li>
                <li className="list-disc ml-2">Los objetos en cualquier otro lugar de la instalación, permanecerán en objetos perdidos por un plazo
                    máximo de 3 semanas. Si en este plazo no es reclamado por su propietario la dirección del centro
                    entregará dichos objetos a una entidad benéfica.
                </li>
            </ul>

            <h2 className="mt-2">11. Notificación en caso de defecto</h2>
            <p>El usuario se compromete a comunicar inmediatamente al personal Moai Climbing cualquier deterioro o
                problema observado en el centro. Creándose así un ambiente más seguro para los usuarios y eliminar los
                riesgos potenciales. La no notificación conllevará consecuencias legales pertinentes por actuación
                negligente.</p>

            <h2 className="mt-2">12. Imágenes y Datos Personales</h2>
            <p>El usuario no podrá tomar vídeos y fotografías en las instalaciones sin la autorización expresa de Moai
                Climbing.</p>
            <p>De acuerdo con la Ley Orgánica de Protección de Datos de Carácter Personal 15/1999, los datos del
                usuario, serán incluidos y tratados en un fichero de usuarios cuyo administrador es Raúl de la Rosa.
                Autorizándose expresamente que sus datos sean utilizados para la gestión de los usuarios de las
                instalaciones, las actividades de Moai Climbing, así como para la realización de campañas de información
                comercial y de marketing. Los interesados podrán ejercer su derecho de acceso, rectificación,
                cancelación u oposición de sus datos, dirigiéndose por escrito y aportando fotocopia de su DNI o
                documento identificativo, en los términos previstos en la Ley, al responsable del Fichero: Moai
                Climbing, C/ Cristóbal Pérez Pastor, 39, 02005- Albacete.</p>

            <h2 className="mt-2">13. Incumplimiento</h2>
            <p>El incumplimiento de estas normas será causa de expulsión automática de las instalaciones y la baja
                definitiva como cliente de Moai Climbing sin ninguna compensación. Moai Climbing se reserva limitar o
                impedir el derecho de admisión a las instalaciones por razón de seguridad o de comportamiento
                inapropiado.</p>

            <h2 className="mt-2">14. Disposiciones adicionales</h2>
            <p>Moai Climbing podrá modificar, desarrollar y actualizar estas Condiciones Generales y sus anexos. Dichos
                cambios entran en vigor inmediatamente después de su publicación en las instalaciones y página web. En
                el caso de existir diferencias, la versión vinculante será de fecha posterior.</p>

            <div class="anexo">
                <h3>Anexo I. Horarios de Apertura de Moai Climbing</h3>
                <p>Horario de apertura de octubre a junio:</p>
                <ul>
                    <li className="list-disc ml-2">Lunes a sábado de 10:00h a 14:00h.</li>
                    <li className="list-disc ml-2">Lunes a viernes de 15:45h a 23:00h.</li>
                </ul>
                <p>La información relativa a este apartado será publicada con antelación en el panel informativo ubicado
                    en recepción y en la página web.</p>
                <p>Durante eventos especiales y competiciones, Moai Climbing podrá no abrir al público general, y/o
                    limitar el acceso.</p>
                <p>Moai Climbing podrá limitar temporalmente el acceso a determinadas zonas de la instalación por
                    motivos y circunstancias razonables como, impartición de clases, competiciones, eventos especiales,
                    labores de limpieza/mantenimiento, equipamiento de vías de escalada.</p>
                <p>Estas limitaciones parciales de acceso serán publicadas con la suficiente antelación no justificando
                    la devolución del precio de la entrada.</p>
            </div>

            <div class="anexo">
                <h3>Anexo II. Precios</h3>
                <p>Los abonos no son reembolsables. Las entradas y abonos deben ser pagados antes de acceder a las
                    instalaciones.</p>
                    <p>Los precios se encuentran a disposición del cliente en la documentación de recepción o en la
                        página web
                        www.moaiclimbing.com</p>
                    <p>Moai Climbing se reserva el derecho de revisar en cualquier momento los precios y las condiciones
                        como
                        notificado en las instalaciones y en la página web. La aplicación de las condiciones en vigor el
                        día de
                        inscripción de cualquier servicio abonado por adelantado se mantendrán durante la duración
                        acordada a la
                        fecha de inscripción.</p>
                    <ul>
                        <li className="list-disc ml-2">Los pases de día y todos los tipos de abono son personales e intransferibles. Los pases de
                            día son
                            válidos únicamente el día de compra.
                        </li>
                        <li className="list-disc ml-2">Los bonos de 10 sesiones son transferibles. Las sesiones no utilizadas no son
                            reembolsables.
                        </li>
                        <li className="list-disc ml-2">Moai Climbing se reserva el derecho de denegar la inscripción como socio así como las
                            condiciones o
                            beneficios asociados.
                        </li>
                        <li className="list-disc ml-2">La duración mínima de esta suspensión es de un mes. Para darse de alta a la cuota de
                            mantenimiento,
                            el socio deberá notificarlo con 5 días de antelación. Los socios que hayan suspendido dicho
                            abono no
                            pueden disfrutar de los beneficios de socio.
                        </li>
                        <li className="list-disc ml-2">Los abonos mensuales domiciliados empiezan el día primero del mes y acaban el último día del
                            mes.
                            Los socios que contraten un nuevo servicio mediante abono mensual, una vez iniciado el mes,
                            pagarán
                            la parte proporcional del mes.
                        </li>
                        <li className="list-disc ml-2">Los días no aprovechados de cualquier abono no son reembolsables.</li>
                        <li className="list-disc ml-2">Para darse de baja de cualquier servicio cuyo pago se encuentre domiciliado, deberán
                            notificarlo por
                            escrito antes del día 25 del mes anterior a la baja.
                        </li>
                        <li className="list-disc ml-2">Los recibos domiciliados de los abonos mensuales y las cuotas de mantenimiento serán
                            cargados a
                            principio de mes.
                        </li>
                        <li className="list-disc ml-2">Cualquier comisión bancaria cargada por razón de insuficiencia de fondos o recibo
                            rechazado/retenido
                            será soportada por el usuario.
                        </li>
                    </ul>
            </div>

            <div class="anexo">
                <h3>Anexo III. Alquiler y préstamo de material</h3>
                <p>Para poder alquilar material, se deberá entregar un documento de identidad válido junto con el
                    importe del
                    alquiler. El solicitante será el responsable del material alquilado, debiéndolo reponer en caso de
                    deterioro, pérdida o hurto. La devolución de material deberá realizarse antes de salir de las
                    instalaciones.</p>
            </div>

            <div class="anexo">
                <h3>Anexo IV. Normas de escalada en bloque</h3>
                <ul>
                    <li className="list-disc ml-2">Antes de escalar mira si no incomodas o interfieres con otro escalador.</li>
                    <li className="list-disc ml-2">No está permitido consumir alimentos ni bebidas sobre la superficie de colchoneta.</li>
                    <li className="list-disc ml-2">No dejes material en la superficie de colchonetas como calzado, ropa, teléfonos, etc.</li>
                    <li className="list-disc ml-2">No está permitido escalar sin camiseta.</li>
                    <li className="list-disc ml-2">No te sientes en las colchonetas ni debajo de los plafones si no estás escalando.</li>
                    <li className="list-disc ml-2">En la medida de lo posible no saltes desde puntos altos, intenta destrepar, una mala caída
                        fortuita
                        puede ser causa de lesión.
                    </li>
                    <li className="list-disc ml-2">Portea y cuida de tus compañeras y compañeros de sala.</li>
                    <li className="list-disc ml-2">Presta la máxima atención a los otros escaladores y respeta a los demás escaladores. No escalar
                        por
                        encima, por debajo o caminar debajo de algún escalador.
                    </li>
                    <li className="list-disc ml-2">Desciende de los bloques siempre con control. Da un aviso verbal para alertar a los demás en la
                        colchoneta. No escalar por encima del final de las paredes, excepto en las áreas designadas para
                        ello.
                        Te recomendamos que amortigües la caída siempre que te sea posible.
                    </li>
                </ul>
            </div>

            <div class="anexo">
                <h3>Anexo V. Normas específicas para grupos y cursos</h3>
                <p>La participación en los cursos (también denominados actividades y clases) exige preinscripción y
                    prepago y
                    depende de las disponibilidades. Los socios tendrán prioridad para apuntarse.</p>
                <p>Las reservas de las actividades ofrecidas por Moai Climbing individuales y de grupo pueden ser
                    canceladas
                    sin cargo 72 horas antes de la actividad, comunicando la cancelación mediante correo electrónico
                    dirigido
                    a info@moaiclimbing.com</p>
                <p>Las cancelaciones hechas sin el preaviso de 72 horas no generarán el derecho a reembolso. Para
                    eventos,
                    competiciones y programas juveniles, podrán aplicarse normas especiales de cancelación, conforme a
                    las
                    peculiaridades propias del evento. Las normas de cancelación aplicables serán publicadas con la
                    anterioridad suficiente.</p>
                <p>Moai Climbing podrá modificar o cancelar los cursos por motivos debidamente justificados. En caso de
                    cancelación por parte de Moai Climbing, se notificará y reembolsará íntegramente a los participantes
                    excepto en caso de fuerza mayor.</p>
            </div>

            <h2 className="mt-2">LEY APLICABLE Y FUERO</h2>
            <p>Las disposiciones contenidas en estas condiciones de uso se regirán y serán interpretadas de conformidad
                con la
                legislación española.</p>
            <p>Salvo que la ley expresamente lo prohíba, para la resolución de todas las cuestiones litigiosas derivadas
                de
                las presentes condiciones de uso, o relativas al incumplimiento, interpretación, resolución o validez de
                cualquier disposición del Sitio Web, las partes, de común acuerdo, se someten al fuero y jurisdicción de
                los
                Juzgados y Tribunales de Albacete, con renuncia expresa a cualquier otro fuero que en su caso pudiera
                corresponderles.</p>

        </>
    )
}